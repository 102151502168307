import Router from "./util/Router";
import common from "./routes/common";
import pageTemplateTemplateP3Projects from "./routes/pageTemplateTemplateP3Projects";
import "../styles/main.scss";

const routes = new Router({
  // All pages
  common,
  pageTemplateTemplateP3Projects,
});

document.addEventListener("DOMContentLoaded", () => {
  routes.loadEvents();
});
