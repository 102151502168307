import React, { Component } from "react";
import App from "./_flipcard.jsx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

interface AppProps {
  //code related to your props goes here
  pageId: string;
  categoryId: string;
}

interface AppState {
  headline: string;
  data: any;
  posts: any;
}

export default class P3Module extends Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      headline: "",
      data: "",
      posts: "",
    };

    this.getACFFields();
    this.getBlogs();
  }

  getACFFields() {
    fetch(`/wp-json/wp/v2/pages/${this.props.pageId}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data: data.acf.modules });
        this.setState({ headline: data.acf.headline });
        console.log("data", this.state.data);
      });
  }

  getBlogs() {
    fetch(`/wp-json/wp/v2/posts/?categories=${this.props.categoryId}&_embed`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ posts: data });
        console.log("posts:", this.state.posts);
      });
  }

  render() {
    let data = Array.from(this.state.data);
    let posts = Array.from(this.state.posts);

    return (
      <section className="p3Projects">
        <div className="container">
          <div className="col-md-12 col-md-offset-1 col-sm-8 col-xs-12 body-txt pull-right about-body">
            <span className="headline">{this.state.headline}</span>
            <span className="projects">
              <div className="">
                {data.map((d: any, idx) => {
                  console.log("MAIN DATA:", d);
                  return (
                    <div className="" key={idx}>
                      <App
                        theFront={d.headline}
                        theBackHeading={d.headline}
                        theBackExcerpt={d.excerpt}
                        buttonText={d.button_text}
                        buttonUrl={d.button_url}
                      />
                    </div>
                  );
                })}
              </div>
            </span>
          </div>
        </div>
        <span className="blogCarousel">
          <img src="/wp-content/uploads/2020/08/anglerightblue.png" />
          <div className="blogContainer">
            <h2>P3 Blogs</h2>
            <Carousel>
              {posts.map((d: any, idx) => {
                let postImage = "";
                if (
                  !d.acf.hasOwnProperty("p3_blog_photo") ||
                  d.acf.p3_blog_photo == ""
                )
                  postImage = d._embedded["wp:featuredmedia"][0].source_url;
                else postImage = d.acf.p3_blog_photo;
                return (
                  <a href={d.link}>
                    <div className="blogPost" key={idx}>
                      <img
                        className="tablet"
                        src="/wp-content/uploads/2020/08/Blog_tablet.png"
                      />
                      <img className="mainImage" src={postImage} />
                    </div>
                  </a>
                );
              })}
            </Carousel>
          </div>
        </span>
      </section>
    );
  }
}
