import P3Module from "../_p3Projects";
import React from "react";
import ReactDOM from "react-dom";

export default {
  init() {
    // 83 categpry id
    const categoryId = document.URL.includes("mbpce.com") ? "108" : "83";
    if (document.getElementById("projectsroot") != null) {
      let pageClass = "";
      document.body.classList.forEach((className) => {
        if (className.includes("page-id")) {
          pageClass = className;
        }
      });
      let pageId = pageClass.split("-")[2];
      let enableModule = false;
      fetch(`/wp-json/wp/v2/pages/${pageId}`)
        .then((response) => response.json())
        .then((result) => {
          enableModule = result.acf.enable_p3_module;
          if (enableModule) {
            ReactDOM.render(
              <P3Module pageId={pageId} categoryId={categoryId} />,
              document.getElementById("projectsroot")
            );
          }
        });
    }
  },

  finalize() {},
};
