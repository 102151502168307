import React, { Component } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import "./App.css";

const FlippyStyle = {
  width: "200px",
  height: "300px",
  textAlign: "center",
  color: "#FFF",
  fontFamily: "sans-serif",
  fontSize: "30px",
  justifyContent: "center",
};

let buttonText123 = "";
let buttonUrl123 = "";

const DefaultCardContents = ({
  frontText,
  theBackHeading,
  theBackExcerpt,
  buttonText,
  buttonUrl,
}) => (
  <React.Fragment>
    <FrontSide
      style={{
        backgroundColor: "#41669d",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="frontContent">
        {frontText}
        <img
          className="arrow"
          src="/wp-content/uploads/2020/08/mbptriangle.png"
        />
      </div>
      <span
        style={{
          fontSize: "12px",
          position: "absolute",
          bottom: "10px",
          width: "100%",
        }}
      ></span>
    </FrontSide>

    <BackSide
      style={{
        backgroundColor: "#175852",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <span className="heading">{theBackHeading}</span>
      <span className="excerpt">{theBackExcerpt}</span>
      <span className="button test123">
        <a customlink={buttonUrl} href={buttonUrl} className="btn">
          {buttonText}
        </a>
      </span>
      <span
        style={{
          fontSize: "12px",
          position: "absolute",
          bottom: "10px",
          width: "100%",
        }}
      ></span>
    </BackSide>
  </React.Fragment>
);

const FlippyOnHover = ({
  flipDirection,
  front,
  backHeading,
  backExcerpt,
  buttonText,
  buttonUrl,
}) => (
  <Flippy flipOnHover={true} flipDirection={flipDirection} style={FlippyStyle}>
    <DefaultCardContents
      frontText={front}
      theBackHeading={backHeading}
      theBackExcerpt={backExcerpt}
      buttonText={buttonText}
      buttonUrl={buttonUrl}
    />
  </Flippy>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false,
    };

    setInterval(() => {
      this.setState({
        isFlipped: !this.state.isFlipped,
      });
    }, 3000);

    buttonText123 = this.props.buttonText;
    buttonUrl123 = this.props.buttonUrl;
    console.log("just set the 123 variables.");
    console.log("buttontext123:", buttonText123);
    console.log("buttonUrl123:", buttonUrl123);
  }

  render() {
    return (
      <div className="App">
        <div
          style={{
            display: "flex",
            flex: "1 0 200px",
            justifyContent: "space-around",
            "flex-wrap": "wrap",
          }}
        >
          <FlippyOnHover
            flipDirection="vertical"
            front={this.props.theFront}
            backHeading={this.props.theBackHeading}
            backExcerpt={this.props.theBackExcerpt}
            buttonText={this.props.buttonText}
            buttonUrl={this.props.buttonUrl}
          />
        </div>
      </div>
    );
  }
}

export default App;
